import AppBanners from "@components/AppBanners";
import React, { useMemo } from "react";
import { Button, Container } from "react-bootstrap";
import ToolLayout from "./ToolLayout";
import { getWindow } from "@utils/window";

// アプリを開くURLだけれどもアプリインストールをしていないときに
// ブラウザで開かれてしまうので、そのときはこのページを表示します。

// https://app.wanpass.me/map?facilityId=aaa のようなURLが開かれたとき、
// このページリダイレクトします。
export const DynamicLinkWraningLayout = () => {
  const _window = getWindow((w) => w);
  const redirectUrl = useMemo<string | null>(() => {
    if (!_window) return "https://app.wanpass.me"; // 画面がチラつくのを防止するためデフォルトはnullにしない。
    const urlParams = new URLSearchParams(_window.location.search);
    const encodedPath = urlParams.get("path");

    // パスが存在する場合はデコードして新しいURLにリダイレクト
    if (!encodedPath) return null;
    const decodedPath = decodeURIComponent(encodedPath);
    if (decodedPath.indexOf("/") !== 0) return null;

    // decodedPath の例： "/map?facilityId=aaa"
    const redirectUrl = "https://app.wanpass.me" + decodedPath;
    return redirectUrl;
  }, [_window]);

  return (
    <ToolLayout title="Wan!Pass（ワンパス）">
      {redirectUrl && (
        <>
          <Container>
            <div
              style={{ textAlign: "center", marginTop: 128, marginBottom: 12 }}
            >
              <a href={redirectUrl}>
                <Button size="lg">Wan!Passアプリで開く</Button>
              </a>
            </div>
            <div
              style={{ fontSize: 14, marginBottom: 96, textAlign: "center" }}
            >
              ※上記のボタンを押してください。
            </div>
          </Container>
          <hr />
        </>
      )}
      <Container style={{ marginTop: 32, marginBottom: 12 }}>
        このボタンは、Wan!Pass（ワンパス）
        アプリをインストールしたスマートフォンで開く必要があります。お手持ちのスマートフォンに
        Wan!Pass（ワンパス） をインストールしてから開いてください。
      </Container>

      {redirectUrl && (
        <>
          <Container>
            Wan!Pass（ワンパス）のインストールが完了しましたら、インストールしたスマートフォンで以下のURLを開いてください。
            <div style={{ textAlign: "center", marginTop: 8 }}>
              <a href={redirectUrl}>{redirectUrl}</a>
            </div>
          </Container>
          <hr />
        </>
      )}
      <Container style={{ marginTop: 32 }}>
        Wan!Pass のインストールは以下からお願いします。
      </Container>
      <Container style={{ maxWidth: 400, marginTop: 16 }}>
        <AppBanners qr />
      </Container>
    </ToolLayout>
  );
};

export default DynamicLinkWraningLayout;
